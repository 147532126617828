@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
	scroll-behavior: smooth;
}

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: #93C5FD;
}

::selection {
  color: white;
  background: #93C5FD;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
  opacity: 0;
}

/* Parallax */
.bg-concept {
  background: url(assets/images/Auditorio.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-about {
  background: url(assets/images//Biblioteca.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

@layer components {
  .bg-main {
    @apply bg-blue-50 dark:bg-gray-800;
  }
  .input {
    @apply py-2 px-4 transition focus:outline-none focus:ring focus:ring-offset-2 
        w-full;
  }
  .btn {
    @apply w-full tracking-wider font-semibold text-sm sm:text-base 
        rounded-xl focus:outline-none;
  }
  .btn-animated {
    @apply hover:scale-105 transform transition;
  }
}
